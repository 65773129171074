import React from 'react';
import PropTypes from 'prop-types';
import {
  getHref,
  getAnchorTag,
  triggerGA,
  relStringGenerator,
  targetStringGenerator
} from '../../../utils/functions';
import { BaseLinkStyles, ArrowLinkStyles } from './BaseLinkStyles';
import { withPrefix } from 'gatsby';

function BaseLink({
  linkType,
  src, // href
  className, // Optional additional css classes
  target, // Applies rel or target attributes using targetStringGenerator() or relStringGenerator()
  gaCategory, // for GA
  gaLabel, // for GA
  id, // for GA
  as, // Optionalvalue of styled-components 'as' prop.  Can render as element other than a tag, such as p tag
  ariaLabel = null,
  onClick,
  event,
  eventProp,
  children // React Children
}) {
  const onClickHandler = (e) => {
    const hreference = getHref(getAnchorTag(e.target));

    // Anchor Tag Handler
    if (hreference.isLocal) {
      document.location = hreference.url;
      return false;
    }

    if (gaCategory && gaLabel) {
      triggerGA(gaCategory, gaLabel);
    }
    // Helper function for Click events
    // Don't pass in variables via the onClick function since it grabs from props provided
    // If we need additional scripts to fire off onClick in the future,
    // We can add a key to the json, check for them, and add the needed logic
    if (onClick) {
      onClick();
    }
    return -1;
  };
  const isAbsolutePath = /^(?:[a-z]+:)?\/\//i.test(src);

  // Determine the href value
  const resolvedSrc = isAbsolutePath ? src : withPrefix(src);
  // Arrow Link
  if (linkType === 'arrowLink' || linkType === 'reversedArrowLink') {
    return (
      <ArrowLinkStyles
        href={resolvedSrc}
        className={className || null}
        id={id}
        onClick={onClickHandler}
        rel={relStringGenerator(target)}
        target={targetStringGenerator(target)}
        as={as || 'a'}
        aria-label={ariaLabel}
        data-event={event}
        data-event-prop={eventProp}
      >
        {linkType === 'arrowLink' ? `${children} →` : `← ${children}`}
      </ArrowLinkStyles>
    );
  }
  // Inline Link
  return (
    <BaseLinkStyles
      href={resolvedSrc}
      className={className || null}
      id={id}
      onClick={onClickHandler}
      rel={relStringGenerator(target)}
      target={targetStringGenerator(target)}
      as={as || 'a'}
      aria-label={ariaLabel}
      data-event={event}
      data-event-prop={eventProp}
    >
      {children}
    </BaseLinkStyles>
  );
}

BaseLink.propTypes = {
  linkType: PropTypes.string,
  src: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  gaCategory: PropTypes.string,
  gaLabel: PropTypes.string,
  as: PropTypes.string,
  ariaLabel: PropTypes.string,
  event: PropTypes.string,
  eventProp: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

BaseLink.defaultProps = {
  linkType: 'inlineLink',
  src: null,
  target: '',
  className: '',
  id: null,
  gaCategory: null,
  gaLabel: null,
  as: 'a',
  ariaLabel: null,
  event: null,
  eventProp: null,
  onClick: false
};

export default BaseLink;
